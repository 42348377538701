import React, { ReactNode, useMemo } from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

interface CustomThemeProviderProps {
  children: ReactNode
}

const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({
  children,
}) => {
  // const prefersDarkMode = window.matchMedia(
  //   "(prefers-color-scheme: dark)"
  // ).matches
  const prefersDarkMode = false

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: prefersDarkMode ? "#edf6ff" : "#141414", // Replace with your primary color
          },
          background: {
            paper: prefersDarkMode ? "#232323" : "#f1f1f1", // Background color for paper elements (like cards)
            default: prefersDarkMode ? "#1a1a1a" : "#ffffff", // Default background color
          },
        },
        typography: {
          fontFamily: ["Outfit", "sans-serif"].join(","),
        },
      }),
    [prefersDarkMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default CustomThemeProvider
