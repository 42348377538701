import { keyframes } from "@mui/material"
import styled from "@emotion/styled"

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  min-height: 96svh;
  max-height: 96svh;
  overflow: hidden;
`

export const BlinkAnimation = keyframes`
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
`

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0.1rem;
  max-width: 90%;
`

export const Step = styled.div`
  margin-bottom: 10px;
  gap: 1rem;
  display: flex;
  align-items: center;
  max-width: 500px;
`

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const BlinkingIcon = styled.span<{ animation: string }>`
  display: inline-block;
  font-size: 24px;
  animation: ${(props) => props.animation} 1.2s infinite;
`
