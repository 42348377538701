import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

const Container = styled.footer`
  background-color: black;
  margin-top: auto;
  width: 100%;
  min-height: 75px;
  padding: 10px;
  gap: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Footer: React.FC = () => {
  const email = "contact@joinlikely.com"

  return (
    <Container>
      <a
        href={`mailto:${email}`}
        style={{ textDecoration: "none", color: "white" }}
      >
        <Typography variant="body2" align="center" style={{ color: "white" }}>
          Contact Us
        </Typography>
      </a>
      <Link
        to={"/terms-of-service"}
        style={{
          textDecoration: "none",
        }}
      >
        <Typography variant="body2" align="center" style={{ color: "white" }}>
          Terms of Service
        </Typography>
      </Link>
      <Link
        to={"/privacy-policy"}
        style={{
          textDecoration: "none",
        }}
      >
        <Typography variant="body2" align="center" style={{ color: "white" }}>
          Privacy Policy
        </Typography>
      </Link>
    </Container>
  )
}

export default Footer
