import React from "react"
import { Helmet } from "react-helmet"

const NoIndex: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {children}
    </>
  )
}

export default NoIndex
