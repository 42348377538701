import React, { useEffect } from "react"
import { APP_STORE_LINK } from "../constants"

const AppStoreRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href = APP_STORE_LINK
  }, [])

  return null
}

export default AppStoreRedirect
