import React from "react"
import { Typography } from "@mui/material"
import styled from "@emotion/styled"
import {
  Container,
  StepsContainer,
  Step,
  Circle,
  BlinkingIcon,
  BlinkAnimation,
} from "../components/styled"
import ArrowUpwardIcon from "@mui/icons-material/North"
import { Link } from "react-router-dom"
import { RED_COLOR, GREY_TEXT } from "../constants"
import useDetectSafari from "../hooks/useDetectSafari"

const activateLikely2 = require("../images/activate-likely-2.png")

const TopArrowWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 10%;
`

const Image = styled.img`
  max-width: 400px;
  width: 60%;
`

const EnableExtensionSecond: React.FC = () => {
  useDetectSafari()
  return (
    <Container>
      <Typography variant="h5">Always allow on every website</Typography>
      <Typography variant="subtitle2" color={GREY_TEXT}>
        Otherwise the like button will not show up
      </Typography>
      <Image src={activateLikely2} alt="Onboarding guide" />
      <StepsContainer>
        <Step>
          <Circle>
            <Typography>1</Typography>
          </Circle>
          <Typography>Tap “Review” in the top right corner</Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>2</Typography>
          </Circle>
          <Typography>Select “Always allow”</Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>3</Typography>
          </Circle>
          <Typography>Select “Always allow, on every website”</Typography>
        </Step>
      </StepsContainer>
      <Link
        to="/enable/third"
        rel="noopener noreferrer"
        style={{ color: "#000" }}
      >
        <Typography>I don't see the review button</Typography>
      </Link>
      <TopArrowWrapper>
        <BlinkingIcon animation={BlinkAnimation}>
          <ArrowUpwardIcon
            fontSize="large"
            style={{ color: RED_COLOR, scale: "1.3" }}
          />
        </BlinkingIcon>
      </TopArrowWrapper>
    </Container>
  )
}

export default EnableExtensionSecond
