import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { APP_STORE_LINK, RED_COLOR } from "../constants"

const logo = require("../images/Likely-logo.png")

const Container = styled.header`
  background-color: white;
  position: fixed;
  padding-left: 15%;
  padding-right: 15%;
  @media (max-width: 1500px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
`

const Logo = styled.img`
  max-width: 40px;
  margin-right: 10px;
`

const GetLikelyLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  background-color: ${RED_COLOR};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 14px;
`

const Header: React.FC = () => {
  return (
    <Container>
      <Link
        to={"/"}
        style={{
          display: "flex",
          flexDirection: "row",
          textDecoration: "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo src={logo} alt="Likely logo" />
        <Typography
          variant="h5"
          align="center"
          style={{
            fontWeight: "bold",
            color: "black",
            alignSelf: "end",
            marginBottom: "-5px",
          }}
        >
          Likely
        </Typography>
      </Link>
      <GetLikelyLink to={APP_STORE_LINK}>
        <Typography
          align="center"
          style={{
            fontWeight: "bold",
            color: "white",
          }}
        >
          Get Likely
        </Typography>
      </GetLikelyLink>
    </Container>
  )
}

export default Header
