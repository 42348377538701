import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Helmet from "react-helmet"
import { APP_STORE_LINK, TIKTOK_LINK } from "../constants"

const likelyGif = require("../images/likely-landing-page.gif")
const appStore = require("../images/app-store.png")
const tiktokLogo = require("../images/Tiktok-logo.png")

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 50px;
  padding-top: 14vh;
`

const MainDiv = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`

const FollowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
`

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-width: 300px;
  gap: 20px;
`

const ImageAppStore = styled.img`
  max-width: 220px;
`

const ImageTikTok = styled.img`
  max-width: 60px;
`

const Gif = styled.img`
  width: 300px;
  border-radius: 20px;
`

const Home: React.FC = () => {
  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: "https://www.joinlikely.com",
    name: "Likely",
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      <Header />
      <Container>
        <MainDiv>
          <TextArea>
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              Like and save any product from any store
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ fontSize: "16px" }}
            >
              With the Likely app and Safari extension, the like button only
              appears on product pages, letting you easily save favorites to the
              app.
            </Typography>
            <Link to={APP_STORE_LINK}>
              <ImageAppStore src={appStore} alt="Download on App Store" />
            </Link>
            <FollowDiv>
              <Typography variant="body2" align="center">
                Follow us.
              </Typography>
              <Link to={TIKTOK_LINK}>
                <ImageTikTok src={tiktokLogo} alt="Follow us on TikTok" />
              </Link>
            </FollowDiv>
          </TextArea>
          <Gif src={likelyGif} alt="How to use Likely" />
        </MainDiv>
        <Footer />
      </Container>
    </>
  )
}

export default Home
