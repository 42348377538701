// src/App.tsx
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import EnableExtensionFirst from "./pages/EnableExtensionFirst"
import EnableExtensionSecond from "./pages/EnableExtensionSecond"
import EnableExtensionThird from "./pages/EnableExtensionThird"
import OnboardingSuccess from "./pages/OnboardingSuccess"
import CustomThemeProvider from "./CustomThemeProvider"
import LinkToList from "./pages/LinkToList"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsOfService from "./pages/TermsOfService"
import Contact from "./pages/Contact"
import NotInSafari from "./pages/NotInSafari"
import NoIndex from "./components/NoIndex"
import AppStoreRedirect from "./pages/AppStoreRedirect"

const App: React.FC = () => {
  return (
    <CustomThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/enable/first"
            element={
              <NoIndex>
                <EnableExtensionFirst />
              </NoIndex>
            }
          />
          <Route
            path="/enable/second"
            element={
              <NoIndex>
                <EnableExtensionSecond />
              </NoIndex>
            }
          />
          <Route
            path="/enable/third"
            element={
              <NoIndex>
                <EnableExtensionThird />
              </NoIndex>
            }
          />
          <Route
            path="/re-enable"
            element={
              <NoIndex>
                <EnableExtensionThird />
              </NoIndex>
            }
          />
          <Route
            path="/not-in-safari"
            element={
              <NoIndex>
                <NotInSafari />
              </NoIndex>
            }
          />
          <Route
            path="/onboarding-success"
            element={
              <NoIndex>
                <OnboardingSuccess />
              </NoIndex>
            }
          />
          <Route
            path="/list/:id"
            element={
              <NoIndex>
                <LinkToList />
              </NoIndex>
            }
          />
          <Route
            path="/re-direct-list/:id"
            element={
              <NoIndex>
                <AppStoreRedirect />
              </NoIndex>
            }
          />
        </Routes>
      </Router>
    </CustomThemeProvider>
  )
}

export default App
