import { useEffect, useState } from "react"

const AddressBarIcon = () => {
  const [isAboveiOS18, setIsAboveiOS18] = useState<boolean | null>(null)

  useEffect(() => {
    const getiOSVersion = (): boolean | null => {
      const userAgent = window.navigator.userAgent
      // Regular expression to find the iOS version in the user agent
      const iOSRegex = /OS (\d+)_?(\d+)?/
      const match = userAgent.match(iOSRegex)

      if (match) {
        const majorVersion = parseInt(match[1], 10) // Extract major iOS version
        console.log("major version", majorVersion)
        return majorVersion >= 18
      }
      return null // Not an iOS device or version can't be determined
    }

    setIsAboveiOS18(getiOSVersion())
  }, [])

  return isAboveiOS18 ? (
    <svg width="16" height="18" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="2"
        rx="2"
        ry="2"
        width="12"
        height="7"
        stroke="black"
        fill="none"
        strokeWidth="2"
      />
      <rect x="2" y="12" width="12" height="2" fill="black" />
      <rect x="2" y="16" width="10" height="2" fill="black" />
    </svg>
  ) : (
    <>
      <strong style={{ fontSize: "14px" }}>A</strong>
      <strong style={{ fontSize: "20px" }}>A</strong>
    </>
  )
}

export default AddressBarIcon
