import React from "react"
import ReactDOM from "react-dom/client" // Import the createRoot method from react-dom/client
import App from "./App"

// Create a root element using createRoot
const root = ReactDOM.createRoot(document.getElementById("root") as Element)

// Render your app
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
