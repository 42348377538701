import React from "react"
import { Typography } from "@mui/material"
import { Container, StepsContainer, Step, Circle } from "../components/styled"
import ArrowRight from "@mui/icons-material/ArrowRight"
import { GREY_TEXT } from "../constants"
import AddressBarIcon from "../icons/AddressBarIcon"
import useDetectSafari from "../hooks/useDetectSafari"

const EnableExtensionThird: React.FC = () => {
  useDetectSafari()
  return (
    <Container>
      <Typography variant="h5">Enable the iOS Safari Extension</Typography>
      <Typography variant="subtitle2" color={GREY_TEXT}>
        Otherwise the like button will not show up
      </Typography>
      <StepsContainer>
        <Step>
          <Circle>
            <Typography>1</Typography>
          </Circle>
          <Typography>
            Tap the <AddressBarIcon /> in the address bar
          </Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>2</Typography>
          </Circle>
          <Typography>Click “Likely”</Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>3</Typography>
          </Circle>
          <Typography>Select “Always allow”</Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>4</Typography>
          </Circle>
          <Typography>Select “Always allow, on every website”</Typography>
        </Step>
      </StepsContainer>
      <StepsContainer>
        <Step style={{ alignSelf: "center" }}>
          <Typography>As a last resort you can go to</Typography>
        </Step>
        <Step style={{ gap: 0, alignSelf: "center" }}>
          <Typography variant="body2" color={GREY_TEXT}>
            Settings App
          </Typography>
          <ArrowRight />
          <Typography variant="body2" color={GREY_TEXT}>
            Safari
          </Typography>
          <ArrowRight />
          <Typography variant="body2" color={GREY_TEXT}>
            Extensions
          </Typography>
          <ArrowRight />
          <Typography variant="body2" color={GREY_TEXT}>
            Likely
          </Typography>
        </Step>
        <Step style={{ alignSelf: "center" }}>
          <Typography align="center">
            then select "Allow" on "All websites". Here you can also enable the
            extension to work on Private Browsing.
          </Typography>
        </Step>
      </StepsContainer>
    </Container>
  )
}

export default EnableExtensionThird
