import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
  max-width: 800px;
  margin: 0 auto;
`;

const StyledMarkdown = styled(ReactMarkdown)`
  line-height: 1.6;

  h1 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 2em;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.5em;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.25em;
  }

  p {
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }
`;

const TermsOfService: React.FC = () => {
  const markdownContent = `
# Terms of Service

**Effective Date**: 2024-09-23

Thank you for using Likely!

These Terms of Service ("Terms") govern your access to and use of any website, app, service, technology, API, widget, platform, channel, or any other products or features owned, operated, branded, or offered by Likely (“Likely” or the “Service”). For the purposes of these Terms, “we” or “us” refers to Likely. Please read these Terms carefully and contact us if you have any questions.

By accessing or using Likely, you agree to be bound by these Terms. If you do not agree to our Terms, you must not access or use Likely.

*More simply put:* These are the rules for using Likely. If you don’t agree, please don’t use our service.

## 1. The Service

Likely allows you to save and organize products from various online stores in one place, helping you keep track of your favorite items and create lists for easy access later. To provide this service effectively, we need to identify you and understand your preferences. We use your personal data to tailor and enhance your experience. For more information on how we collect, use, and protect your personal information, please read our Privacy Policy.

*More simply put:* Likely helps you save and organize your favorite products from across the web.

## 2. Using Likely

### a. Who can use Likely

You may use Likely only if you agree to these Terms and all applicable laws. Using Likely may include downloading software to your computer, phone, tablet, or other device. You agree that we may automatically update that software, and these Terms will apply to any updates. When you create your Likely account, you must provide accurate and complete information.

You must be at least 13 years old to use Likely. If you are between 13 and 18, you may only use the Services with the permission of your parent or legal guardian. Please make sure your parent or legal guardian has reviewed and discussed these Terms with you.

If we’ve previously disabled your account for violation of these Terms, you will not create a new Likely account without our express written permission.

In using Likely, you agree not to scrape, collect, search, copy, or otherwise access data or content from Likely in unauthorized ways, such as by using automated means (without our express prior permission), or access or attempt to access data you do not have permission to access.

### b. Our License to You

Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable, and revocable license to access and use the Service as described in these Terms.

*More simply put:* You get a license to use Likely under these terms, but you cannot transfer this license to others.

You may not: (i) modify or make derivative works based upon the Service; (ii) reverse engineer the Service; or (iii) copy any features or functions of the Service.

## 3. Your User Content

Likely allows you to save content, including product links, comments, and other materials. Anything you save or post is referred to as "User Content." You retain all rights in, and are solely responsible for, the User Content you post to Likely. All User Content must comply with these Terms and our policies.

You will only post User Content that you have the rights to post, and you won’t post User Content that infringes the intellectual property rights of others or that is otherwise unlawful.

We can take action against User Content that violates these Terms or our policies or where we are permitted or required by law, such as by removing, restricting, or limiting access to, or distribution of, the content.

*More simply put:* You own the content you post, but you must have the rights to post it and follow our rules.

By posting User Content on Likely, you grant us and our affiliates and service providers, and our users, a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, publicly perform or display, reproduce, modify, create derivative works, monetize, download, translate, and distribute your User Content.

We reserve the right to remove, limit distribution of, or modify User Content, or change the way it’s used in Likely, for any reason in our sole discretion, and without notice.

## 4. Intellectual Property

As between you and us, we own all rights, title, and interest in and to Likely and all intellectual property rights therein. You must not use any of our intellectual property, except as necessary for your permitted use of Likely.

*More simply put:* We own the intellectual property of Likely, and you should respect these rights.

You acknowledge and agree that nothing in these Terms grants you any right to use Likely's trademarks, logos, domain names, or other brand features.

## 5. Security

We care about the security of our users. While we work to protect the security of your account, we cannot guarantee that unauthorized third parties won’t be able to defeat our security measures. Keep your password secure and notify us immediately of any unauthorized access to your account.

*More simply put:* Help us keep Likely secure by following security best practices.

## 6. Third-Party Links, Sites, and Services

Likely may contain links to third-party websites, services, or other events or activities that are not owned or controlled by us. We don’t endorse or assume any responsibility for any such third-party websites, information, materials, products, or services.

*More simply put:* We are not responsible for any content you view on, or your use of, third-party websites or services.

If you access any third-party website, service, or content from Likely, you do so at your own risk, and you agree that we have no liability arising from your use of any third-party websites, services, or content.

## 7. Termination

We may terminate or suspend your right to access or use Likely for any reason at our sole discretion.

*More simply put:* We reserve the right to refuse service to anyone.

Upon termination, Sections 3, 8, 9, 10, 11, and 12 of these Terms will continue to be in operation.

## 8. Disclaimers

TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE AND ALL CONTENT ON LIKELY IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED.

*More simply put:* We work to provide the best service possible, but it won't always be perfect. Use Likely at your own risk.

Likely specifically disclaims all warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement, and any warranties arising out of course of dealing or usage of trade.

## 9. Limitation of Liability

TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES.

*More simply put:* We are not liable for various losses or damages that may occur while using Likely.

In no event shall our aggregate liability for all claims relating to the Service exceed one hundred US dollars (U.S. $100.00).

## 10. Governing Law and Jurisdiction

These Terms and your use of the Service shall be governed by the laws of Sweden.

*More simply put:* Legal matters will be handled in Sweden.

## 11. General Terms

We may update these Terms from time to time. The most current version will always be posted on our website. Your continued use of Likely after updates are posted constitutes your acceptance of the changes.

*More simply put:* If we update these terms, we'll let you know. If you don’t agree with the new terms, please stop using Likely.

If we make material changes to these Terms, we will provide you with notice as appropriate under the circumstances, such as by displaying a prominent notice within the Service or by sending you an email.

No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.

If any provision of these Terms is deemed invalid, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.

You may not assign any of your rights under these Terms without our consent. We may assign our rights to any of our affiliates or subsidiaries, or to any successor in interest of any business associated with the Service.

## 12. Contact Information

If you have any questions about these Terms, please contact us at:

Likely Technologies AB  
Hägerstensvägen 203  
Hägersten, Sweden

Email: contact@joinlikely.com

`;

  return (
    <Container>
      <StyledMarkdown>{markdownContent}</StyledMarkdown>
    </Container>
  );
};

export default TermsOfService;
