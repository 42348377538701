import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, CircularProgress, Typography } from "@mui/material"
import styled from "@emotion/styled"
import { BACKEND_URL, GREY_TEXT } from "../constants"
import ProductCard from "../components/ProductCard"
import InformationSection from "../components/InfoDownloadSection"
import ListHeader from "../components/ListHeader"
import Footer from "../components/Footer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  min-height: 100vh;
`

const LinkToList: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const linkPath = `list/${id}`
  const deepLink = `likely://${linkPath}`
  const buttonUniversalLink = `/re-direct-${linkPath}`

  const fetchProductsUrl = `${BACKEND_URL}/groups/public-group-info/${id}`

  const [listName, setListName] = useState<string>("")
  const [products, setProducts] = useState<
    {
      id: string
      title: string
      imageUrl: string
      formattedPrice: string
      store: string
      productUrl: string
    }[]
  >([])
  const [isError, setIsError] = useState(false)
  const [isPrivate, setIsPrivate] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true)
      try {
        const response = await fetch(fetchProductsUrl)
        if (!response.ok) {
          if (response.status === 403) {
            setIsPrivate(true)
          } else if (response.status === 404 || response.status === 400) {
            setIsNotFound(true)
          } else {
            throw new Error(`Error: ${response.statusText}`)
          }
        } else {
          window.location.href = deepLink
        }
        const data = await response.json()

        setListName(data.group?.name || "Unnamed List")
        setProducts(data.likes || [])
      } catch (error) {
        console.error("Failed to fetch products:", error)
        setIsError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchProducts()
  }, [id, deepLink, fetchProductsUrl])

  return (
    <Container>
      {/* Using the linkPath uses universal link to link to open app or app store if it isnt installed */}
      <ListHeader buttonLink={deepLink} />
      <Box
        sx={{
          marginTop: 10,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <InformationSection
            boldText="Something went wrong"
            descriptionText="Check your internet connection and please contact us if this keeps happening"
          />
        ) : isNotFound ? (
          <InformationSection
            boldText="We couldn’t find this list"
            descriptionText="Double check that the link is correct"
          />
        ) : isPrivate ? (
          <InformationSection
            boldText="This list is private"
            descriptionText="The person that sent you this list needs to make the list public in order for you to see it"
          />
        ) : (
          <>
            <Typography variant="h5" textAlign="left" fontWeight="bold">
              {listName}
            </Typography>
            <Typography
              variant="h4"
              textAlign="left"
              sx={{
                fontSize: "11px",
                color: GREY_TEXT,
                marginBottom: "13px",
                marginTop: "4px",
              }}
            >
              {products?.length ? `${products.length} products` : "No products"}
            </Typography>
            {!products?.length ? (
              <InformationSection
                boldText="This list is empty"
                descriptionText="Create your own lists by downloading the app"
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  justifyContent: "left",
                  width: {
                    xs: "356px",
                    md: "728px",
                  },
                }}
              >
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    title={product?.title}
                    imageUrl={product?.imageUrl}
                    price={product?.formattedPrice}
                    store={product?.store}
                    productUrl={product.productUrl}
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
      <Footer />
    </Container>
  )
}

export default LinkToList
