import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const useDetectSafari = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const userAgent = window.navigator.userAgent

    const isSafari =
      /Safari/.test(userAgent) &&
      !/CriOS/.test(userAgent) &&
      !/FxiOS/.test(userAgent) &&
      !/EdgiOS/.test(userAgent) &&
      !/Chrome/.test(userAgent) &&
      !/Firefox/.test(userAgent) &&
      !/Edg/.test(userAgent)

    if (!isSafari) {
      navigate("/not-in-safari")
    }
  }, [navigate])
}

export default useDetectSafari
